<template>
  <header
    id="header"
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="navsizer">
      <router-link to="/" class="tabs__nav-item logo" @click="clicked">
        <img src="images/transparent.png" />
      </router-link>
      <div class="nav-lines" ref="menu" @click="menu">
        <div class="line"></div>
      </div>
      <div class="content__inner">
        <div class="tabs__nav">
          <ul class="tabs__nav-list" ref="nav">
            <router-link to="/" class="tabs__nav-item" @click="clicked"
              >Home</router-link
            >
            <router-link to="/hey" class="tabs__nav-item" @click="clicked"
              >.hey 👋</router-link
            >
            <router-link to="/about" class="tabs__nav-item" @click="clicked"
              >About</router-link
            >
            <router-link to="/resources" class="tabs__nav-item" @click="clicked"
              >Resources</router-link
            >
            <router-link to="/community" class="tabs__nav-item" @click="clicked"
              >Live Sites</router-link
            >
            <router-link to="/partners" class="tabs__nav-item" @click="clicked"
              >Partners</router-link
            >
          </ul>
          <div
            ref="deco"
            class="tabs__nav-decoration js-decoration"
            style="display: none; width: 85px; transform: translateX(0px)"
          ></div>
        </div>
      </div>
    </div>
  </header>

  <div class="sidebar" ref="sidebar">
    <ul class="sidebar-list">
      <router-link to="/" class="sidebar-anchor" @click="clicked">
        <li class="sidebar-item">Home</li></router-link
      >

      <router-link to="/hey" class="sidebar-anchor" @click="clicked">
        <li class="sidebar-item">.hey 👋</li></router-link
      >

      <router-link to="/about" class="sidebar-anchor" @click="clicked">
        <li class="sidebar-item">About</li></router-link
      >

      <router-link to="/resources" class="sidebar-anchor" @click="clicked">
        <li class="sidebar-item">Resources</li></router-link
      >

      <router-link to="/community" class="sidebar-anchor" @click="clicked">
        <li class="sidebar-item">Live Sites</li></router-link
      >

      <router-link to="/partners" class="sidebar-anchor" @click="clicked">
        <li class="sidebar-item">Partners</li></router-link
      >
    </ul>
  </div>

  <router-view
    :showcase="showcase"
    :articles="articles"
    @grabshowcase="grabshowcase"
    @grabarticles="grabarticles"
    @modloader="modloader"
    @preloadpage="preloadpage"
    :loading="this.loading"
    :alreadyexplored="this.alreadyexplored"
  />

  <Footer @clicked="clicked" />
</template>

<script>
import Footer from "./components/Footer.vue";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

preload(["images/heytx2.png"]);

async function preload(images) {
  let promiseArray = [];

  images.forEach((val) => {
    promiseArray.push(
      new Promise((resolve) => {
        var img = new Image();

        img.onload = function () {
          resolve();
        };

        img.src = val;
      })
    );
  });
}

export default {
  name: "App",
  components: {
    Footer,
  },
  data() {
    return {
      activeTab: null,
      showcase: null,
      articles: null,
      loading: 0,
      alreadyexplored: [1],
      active: null,
    };
  },
  async mounted() {
    this.loopNav();
    let activeTabx;
    window.addEventListener(
      "resize",
      async () => {
        activeTabx = document.querySelector(".router-link-active");
        if (activeTabx?.children[0]?.src) {
          activeTabx = this.$refs.nav.children[0];
        }
        await sleep(150);
        this.$refs.deco.style.transform = `translateX(${activeTabx.offsetLeft}px)`;
        this.$refs.deco.style.width = `${activeTabx.offsetWidth}px`;
        this.$refs.deco.style.height = `${activeTabx.offsetHeight}px`;
        this.$refs.deco.style.display = "block";
      },
      true
    );
  },
  emits: [],
  methods: {
    updateDecorationColor() {
      if (this.activeTab && this.activeTab.getAttribute("href") === "/hey") {
        this.$refs.deco.style.backgroundColor = "#022d50";
      } else {
        this.$refs.deco.style.backgroundColor = "black";
      }
    },
    async loopNav() {
      for (let item of this.$refs.nav.children) {
        if (item.classList.contains("router-link-active")) {
          this.active = item;
          this.$refs.deco.style.transform = `translateX(${item.offsetLeft}px)`;
          this.$refs.deco.style.width = `${item.offsetWidth}px`;
          this.$refs.deco.style.display = "block";
          this.updateDecorationColor(); // Call the function to update the color based on the activeTab
        }
      }
      if (!this.active) {
        await sleep(230);
        return this.loopNav();
      }
    },

    async menu() {
      this.$refs.menu.classList.toggle("active");
      this.$refs.sidebar.style.display = "block";
      await sleep(1);
      this.$refs.sidebar.classList.toggle("active");
    },
    async grabarticles() {
      fetch("./articles.json?nocache=" + new Date().getTime())
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          this.articles = data;
        })
        .catch((error) => {
          this.errorMessage = error;
          console.error("There was an error!", error);
        });
    },
    async grabshowcase() {
      fetch("./results.json?nocache=" + new Date().getTime())
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          this.showcase = data;

          let images = [];
          for (let i = 0; i < 5; i++) {
            images.push(this.showcase[i].img);
          }

          await preload(images);

          await sleep(10);
          this.modloader(1);
        })
        .catch((error) => {
          this.errorMessage = error;
          console.error("There was an error!", error);
        });
    },
    async preloadpage(x) {
      this.modloader(0);
      let images = [];
      for (let i = x; i < x + 5; i++) {
        images.push(this.showcase[i].img);
      }
      if (this.alreadyexplored.includes(x)) {
        return this.modloader(1);
      }
      await preload(images);
      await sleep(80);
      this.modloader(1);
      return this.alreadyexplored.push(x);
    },
    modloader(x) {
      this.loading = x;
    },
    async clicked(e, text) {
      if (e === 1 && text) {
        for (let item of this.$refs.nav.children) {
          if (item.textContent.toLowerCase().trim() === text.toLowerCase()) {
            this.activeTab = item;
            this.$refs.deco.style.transform = `translateX(${this.activeTab.offsetLeft}px)`;
            this.$refs.deco.style.width = `${this.activeTab.offsetWidth}px`;
          }
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return;
      }
      if (e.target.closest(".sidebar")) {
        this.$refs.sidebar.classList.toggle("active");
        return this.$refs.menu.classList.toggle("active");
      }
      if (e.target?.src || e.target?.children[0]?.src) {
        this.activeTab = this.$refs.nav.children[0];
        this.$refs.deco.style.transform = `translateX(${this.activeTab.offsetLeft}px)`;
        this.$refs.deco.style.width = `${this.activeTab.offsetWidth}px`;
        return;
      }
      this.activeTab = e.target;

      this.$refs.deco.style.transform = `translateX(${this.activeTab.offsetLeft}px)`;
      this.$refs.deco.style.width = `${this.activeTab.offsetWidth}px`;
      this.updateDecorationColor();
      return;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,500&display=swap");

#app {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  width: 100%;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tabs__nav {
  position: relative;
  margin-left: 1.5rem;
  margin-left: 0.75rem;
}

.tabs__nav-decoration {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.2s linear 0s, transform 0.2s ease-out 0s;
  background-color: black;
  border-radius: 1px;
  z-index: 1;
}

.tabs__nav-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  z-index: 5;
  padding: 0;
}
.router-link-active {
  color: #fff !important;
}

.tabs__nav-item {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  padding: 15px;
  cursor: pointer;
  border-radius: 2px;
  font-size: clamp(0.9rem, 2vw, 1.3rem);
  white-space: nowrap;
}

.tabs__nav li:not(:first-child) {
}

.tabs__nav li:first-child {
}

.tabs__nav-item.js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0.05s;
  color: #fff;
}

.tabs__panels {
  position: relative;
  margin-top: 30px;
}

.tabs__panel {
  position: absolute;
  top: 0;
  left: 0;
  transition: none;
  transform: scale(0.8);
  width: 100%;
  opacity: 0;
}

.tabs__panel.js-active {
  transition: all 0.25s linear 0s;
  transform: scale(1);
  opacity: 1;
}

.tabs__panel-card {
  display: flex;
  margin-bottom: 30px;
  padding: 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.tabs__panel-card:last-child {
  margin-bottom: 0;
}

.tabs__panel-card--spaced-between {
  justify-content: space-between;
}

.content__inner {
  width: 100%;
}

.tabs__nav {
}

.tabs__nav-list {
  position: relative;
  justify-content: space-between;
  list-style-type: none;
  z-index: 5;
}

.tabs__nav-item {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  padding: 15px;
  cursor: pointer;
  display: inline-block;
}

header img {
  width: auto;
  margin-right: clamp(0rem, 1.7vw, 8rem);
  height: clamp(55px, 8.5vw, 70px);
}

header {
  border-bottom: 1px solid #000;
  height: 96px;
}

.navsizer {
  width: clamp(648px, 79.5vw, 985px);
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-lines {
  margin-right: 2rem;
  display: none;
  height: 75px;
}

.line {
  width: 46px;
  height: 4px;
  background: #000;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.line:before {
  top: 13px;
  content: "";
  width: 46px;
  height: 4px;
  background: #000;
  position: absolute;
  transition: 0.3s;
}

.line:after {
  top: -13px;
  content: "";
  width: 46px;
  height: 4px;
  background: #000;
  position: absolute;
  transition: 0.3s;
}

.nav-lines.active .line {
  background: #fff;
}

.nav-lines.active .line::after {
  top: 0;
  transform: rotate(-45deg);
}

.nav-lines.active .line::before {
  top: 0;
  transform: rotate(45deg);
}

.sidebar {
  height: 100%;
  width: 100vw;
  top: 96px;
  z-index: 999;
  right: 0;
  background-color: #000;
  transition: all 200ms;
  position: absolute;
  transform: translateX(100vw);
  display: none;
}

.sidebar.active {
  transform: none;
}

.sidebar ul li {
  padding: 1.2rem;
  font-size: 2.4rem;
  padding-left: 2rem;
}

.sidebar ul .current {
  background: #fff;
  color: #000;
}

.sidebar ul .current a,
.sidebar ul .current a:visited {
  color: #000 !important;
}

.sidebar ul a,
.sidebar ul a:visited {
  color: #fff;
}

a,
a:visited {
  color: #000;
  text-decoration: none;
}

@media (max-width: 768px) {
  html,
  body {
    overflow-x: hidden;
    height: 100vh;
  }
  body {
    position: relative;
  }
  .content__inner {
    display: none;
  }
  .router-link-active li {
    background: #fff;
    color: #000;
  }

  .navsizer {
    width: 100%;
  }
  .navsizer img {
    margin-right: auto;
    margin-left: 2rem;
    margin: 0 !important;
  }

  .nav-lines {
    display: block;
    margin-left: auto;
  }
  .logo img {
    margin: 0;
  }
  .logo {
    margin-right: auto;
  }
}
@media (max-width: 400px) {
  .sidebar ul li {
    font-size: 1.5rem;
    padding-left: 1rem;
  }
}
</style>
