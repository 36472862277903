<template>
  <PopUpHey
    v-if="popup === 1"
    @clicked="handleNavigation"
    @closemodal="closemodal"
    :popup="popup"
  />

  <div class="firstwrapper" ref="styler" style="min-height: 250px">
    <div class="h1holder">
      <div class="inside">
        <h1 id="typewriter" class="cursor"></h1>
        <h2 style="margin-top: 4rem">Now Available On</h2>
        <div class="options">
          <section style="text-align: center">
            <ul class="carousel noselect2">
              <a
                class="carousel-control lefterx"
                href=""
                role="button"
                data-slide="prev"
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAB1ElEQVRoge3ZOW8TURRA4U8gGcWRErNkA6KwNawV/GN6tgaxBDq2hLAmrMLQsEm00IViMkrxMti+Fm/GVo50O0tzjmZ07fFjl13GjhYuYw1LNbuEaeEKNrfmM47XahSghau2I8rp4liNXgPRwjVpxEjFtHBddUQ573GkJsee7MMNvSPKeaeBMRO4qf+IcjYUd7ERTOCWwSM28ROT+ZVT2rgtFvELl/Irp7RxRzziYn7llDbuikX8wIX8yinDRpzPr5wyiWWxiO8aFHFPPOJcfuWUsYm4LxbxDWfzK6dM44F4xJn8yinDRHzVoIiH4hGn8yundPBILOILTuVXThk24mR+5Z2JbqcuTvxvuT0DfPZP8Bq/t6YxTIlvqrc4nF+5minxjfUGC/mVqxlm/W5gPr9yNdPiG6xxMR08FotZx1x+5Wo6eCIW8xyH8itXM0zMMxzMr1zNfqyIxaxpYMyqMYmZwQuxmKc4kF+5mhm8NCYxs+Ixq4rHtDHM4pVYzIqGxcwrvskjMcs1+P6TBcUPxkFD1uuQ7cWcQqzfiCwvY1H6fcy6RuCE9yg+qI74ZAQOQ0sW7RwzUhEli/hoO6KRh5/9sqS4M6817H0+wl6D/ZOzy8jwF+1iHAQ7vBt6AAAAAElFTkSuQmCC"
                />
              </a>

              <a
                class="carousel-control righterx"
                href=""
                role="button"
                data-slide="prev"
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABs0lEQVRoge3YS2sTURjG8Z/unIKpYmLVIt4WXj674L5qdWXV4h1bK2gvi/YTCAWhXcTQYDrM5B175kzMH84uwzx/TnjPmYc5c7KlwGP8wP2Ws4QpsIKjP+sAD1tNFKDAUycS4zIPWsw1FQWemZQYrX0dkCnwXLnEaO3hXksZK1lQT2Jc5m4rSSt4or7EaO3g9lkHOz/l7w8D77iJVdwKPHtmXHD6pMpmZ6ahamL9NzLbMvub1R3DnZFZFZP5jhvpI5ezgBdiMlsylHkpLnM9feRymsh8k5nMRayJyWziWvrI5fTMmMxrMZkNLKWPXE4Pb8yIzKK4zEdcSR+5nMt4LyazjnN1XjLtNT7Cb/wKPntVmoyV9PBKbDcO8Ch95EmaSOzLpFZqcpZk08Asip8h2TQvTSR2ZSQRPTN2ZVIbXcJbMYkdMyJxJ33kSfqGV4mIxLZMGpU+Pum4xEBc4qdMGpQBPotJbGE5feRJmkpk05hEr+ObMisXIvenDZl9hzM8M9Z1eCfGqTt2s9yJv6mS6YTEiL7TJ9hXmTUgdRjgixOJD4aCnWQJ7ww/a7Oqb+bM+QccA7SiDoF+dVMuAAAAAElFTkSuQmCC"
                />
              </a>

              <li class="items right">
                <a href="https://porkbun.com/tld/tx" target="_blank">
                  <div class="registrar">PorkBun</div>
                </a>
              </li>
              <li class="items left">
                <a href="https://www.encirca.com/handshake-tx/" target="_blank">
                  <div class="registrar">EnCirca</div>
                </a>
              </li>
              <li class="items main">
                <a
                  href="https://www.namecheap.com/domains/handshake-domains/"
                  target="_blank"
                >
                  <div class="registrar orange">NameCheap</div>
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>

    <ul class="optionsmobile">
      <li class="mitems">
        <a
          href="https://www.namecheap.com/domains/handshake-domains/"
          target="_blank"
        >
          <div class="registrar orange">NameCheap</div>
        </a>
      </li>
      <li class="mitems">
        <a href="https://www.encirca.com/" target="_blank">
          <div class="registrar">EnCirca</div>
        </a>
      </li>
      <li class="mitems">
        <a href="https://porkbun.com/tld/tx" target="_blank">
          <div class="registrar">PorkBun</div>
        </a>
      </li>
    </ul>
  </div>

  <div class="sectionwrapper black paddingfix">
    <div class="whytx whytxcontainer">
      <div class="vidholder">
        <iframe
          src="https://www.youtube-nocookie.com/embed/f0F2xKGA3xk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="whytxholder">
        <h2>Why TX?</h2>
        <div class="whytxholder2">
          <div style="background: #000; padding: 0.4rem">
            <div style="background: #fff; padding: 0.6rem">
              <ul class="dashed">
                <li>.tx is the domain for Texas</li>
                <li>.tx can be your digital identity</li>
                <li>.tx stands for transactions</li>
                <li>.tx puts you in control of your online experience</li>
                <li>.tx unlocks the decentralized web for you</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sectionwrapper" style="min-height: 600px">
    <div class="straightener">
      <h2 style="margin-bottom: 3rem">How to Get Started</h2>
    </div>

    <div class="getstarted">
      <div class="numberholder">
        <div class="getstartedholder">
          <div class="outer2">
            <div style="background: #fff; padding: 0.1rem">
              <h2 class="number">1</h2>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: -2rem">
        <h3
          style="
            font-size: 1.8rem;
            margin: 0.5rem;
            margin-bottom: 1rem;
            color: #000;
          "
        >
          Grab A Name
        </h3>
        <div class="getstartedholder">
          <div class="outer2">
            <div
              class="whytx"
              style="
                width: 100%;
                background: #fff;
                padding: 0.6rem;
                color: #000;
                border: 1px solid #000;
              "
            >
              Pick a registrar and buy a unique .tx domain name to get started.
              Choose wisely!
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="getstarted" style="margin-top: 5rem">
      <div class="numberholder">
        <div class="getstartedholder">
          <div class="outer2">
            <div style="background: #fff; padding: 0.1rem">
              <h2 class="number">2</h2>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: -2rem">
        <h3
          style="
            font-size: 1.8rem;
            margin: 0.5rem;
            margin-bottom: 1rem;
            color: #000;
          "
        >
          Create
        </h3>
        <div class="getstartedholder">
          <div style="background: #fff; padding: 0.4rem">
            <div
              class="whytx"
              style="
                width: 100%;
                background: #fff;
                padding: 0.6rem;
                color: #000;
                border: 1px solid #000;
              "
            >
              A .tx name can be used for: website, email, single-click login,
              universal username, wallet name, and more!
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="getstarted" style="margin-top: 5rem">
      <div class="numberholder">
        <div class="getstartedholder">
          <div class="outer2">
            <div style="background: #fff; padding: 0.1rem">
              <h2 class="number">3</h2>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: -2rem; width: 100%">
        <h3
          style="
            font-size: 1.8rem;
            margin: 0.5rem;
            margin-bottom: 1rem;
            color: #000;
          "
        >
          Showcase Your Name
        </h3>
        <div class="getstartedholder">
          <div style="background: #fff; padding: 0.4rem">
            <div
              class="whytx"
              style="
                width: 100%;
                background: #fff;
                padding: 0.6rem;
                color: #000;
                border: 1px solid #000;
              "
            >
              Get out there and show the world what you have made. There is no
              limit to what a decentralized .tx domain can do.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sectionwrapper black">
    <div class="straightener">
      <h2>Marketing & Logos</h2>

      <div class="assetholder">
        <div
          class="marginright4rem"
          style="background: #fff; padding: 0.4rem; height: auto"
        >
          <div style="background: #000; padding: 0.4rem">
            <div style="background: #fff; padding: 0.6rem">
              <img src="images/com-tx.png" />
            </div>
          </div>
        </div>

        <div
          class="marginright4rem"
          style="background: #fff; padding: 0.4rem; height: auto"
        >
          <div style="background: #000; padding: 0.4rem">
            <div style="background: #fff; padding: 0.6rem">
              <img src="images/com-tx2.png" />
            </div>
          </div>
        </div>

        <div
          style="
            text-align: center;
            background: #fff;
            margin-right: 0rem;
            padding: 0.4rem;
            height: auto;
          "
        >
          <div style="background: #000; padding: 0.4rem">
            <div style="background: #fff; padding: 0.6rem">
              <img src="images/com-tx3.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accessassets">
      <a
        target="_blank"
        href="https://drive.google.com/drive/folders/1RxZ-uGTfgXGnDYFzxGDSseTjkKpMIfEx?usp=sharing"
        ><div>Access the Creative Assets</div></a
      >
    </div>
  </div>

  <div class="sectionwrapper imghighlight">
    <div class="straightener">
      <h2>Latest From TX</h2>
    </div>

    <div class="articlesholder">
      <div class="articlewidth" v-for="(item, key) in this.articles" :key="key">
        <a target="_blank" :href="item.url">
          <div class="articleholder main">
            <div style="background: #000; padding: 0.4rem">
              <div
                class="whytx"
                style="
                  background: #fff;
                  padding: 0.6rem;
                  color: #000;
                  width: 100%;
                "
              >
                <img style="max-width: 100%" :src="item.img" />
              </div>
            </div>
          </div>

          <div class="articleholder">
            <div style="background: #000; padding: 0.4rem">
              <div
                class="whytx"
                style="
                  background: #fff;
                  padding: 0.6rem;
                  color: #000;
                  width: 100%;
                "
              >
                <h2 class="articleh2">{{ item.title }}</h2>
                <p>
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PopUpHey from "../components/PopUpHey.vue";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  name: "Home",
  inheritAttrs: false,
  components: {
    PopUpHey,
  },
  data() {
    return {
      popup: 1,
      rotate: 0,
    };
  },
  props: ["articles"],
  emits: ["grabarticles"],
  methods: {
    handleNavigation(event, path) {
      // Additional logic if needed
      this.$router.push(path);
    },
    closemodal() {
      this.popup = 0;
      localStorage.setItem("modalClosed", "true");
    },

    startTyping() {
      let num = this.rotate;
      let element = document.querySelector("#typewriter");
      var textArray = ["Name.tx", "Business.tx", "City.tx", "Anything.tx"];
      let key = 0;
      element.textContent = "";

      async function TypeWriter(element, text) {
        typewriter(element, text).then(async () => {
          element.classList.add("blink");
          await sleep(800);
          element.classList.remove("blink");
          backspacer(element, text).then(async () => {
            if (key < textArray.length - 1) {
              key++;
            } else {
              key = 0;
            }
            return TypeWriter(element, textArray[key]);
          });
        });
      }

      async function backspacer(element, text, delay = 50) {
        for (let i = 0; i < text.length; i++) {
          await sleep(delay);
          element.textContent = element.textContent.slice(
            0,
            element.textContent.length - 1
          );
        }
      }

      async function typewriter(element, text, delay = 80) {
        for (let i = 0; i < text.length; i++) {
          await sleep(delay);
          element.innerHTML += text[i];
        }
      }

      if (num === this.rotate) {
        TypeWriter(element, textArray[key]);
      }
    },
  },
  mounted() {
    if (localStorage.getItem("modalClosed") === "true") {
      this.popup = 0;
    }

    if (this.articles === null) {
      this.$emit("grabarticles");
    }

    this.startTyping();

    var getNextSibling = function (elem, selector) {
      var sibling = elem.nextElementSibling;

      while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
      }
    };

    var getPreviousSibling = function (elem, selector) {
      var sibling = elem.previousElementSibling;
      if (!selector) return sibling;
      while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
      }
    };

    function change(direction) {
      if (direction === "right") {
        let items = document.querySelectorAll(".items");
        let itemLength = items.length;

        let right = document.querySelector(".right");
        let left = document.querySelector(".left");
        let afterRight = getNextSibling(right, ".items") || items[0];
        let beforeRight =
          getPreviousSibling(right, ".items") || items[itemLength - 1];

        left.classList.remove("left");

        right.classList.remove("right");
        right.classList.add("main");

        afterRight.classList.add("right");

        beforeRight.classList.remove("main");
        beforeRight.classList.add("left");
      } else if (direction === "left") {
        let items = document.querySelectorAll(".items");
        let itemLength = items.length;

        let right = document.querySelector(".right");
        let left = document.querySelector(".left");
        let beforeLeft =
          getPreviousSibling(left, ".items") || items[itemLength - 1];
        let main = document.querySelector(".main");

        left.classList.remove("left");
        left.classList.add("main");

        main.classList.remove("main");
        main.classList.add("right");

        right.classList.remove("right");

        beforeLeft.classList.add("left");
      }
    }

    let nextBut = document.querySelector(".righterx");
    let prevBut = document.querySelector(".lefterx");

    nextBut.addEventListener("click", function (e) {
      e.preventDefault();
      change("right");
    });

    prevBut.addEventListener("click", function (e) {
      e.preventDefault();
      change("left");
    });

    var observer = new IntersectionObserver(onIntersection, {
      root: null,
      threshold: 0.2,
    });

    function onIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("bigger");
        }
      });
    }

    observer.observe(document.querySelectorAll(".getstarted")[0]);
    observer.observe(document.querySelectorAll(".getstarted")[1]);
    observer.observe(document.querySelectorAll(".getstarted")[2]);
  },
};
</script>

<style>
.sidebar {
  height: 100%;
  width: 100vw;
  top: 96px;
  z-index: 9999999;
  right: 0;
  background-color: #000;
  transition: all 200ms;
  position: absolute;
  transform: translateX(100vw);
}

.sidebar.active {
  transform: none;
  z-index: 99999;
}

.sidebar ul .current {
  background: #fff;
  color: #000;
}

.sidebar ul .current a,
.sidebar ul .current a:visited {
  color: #000;
}

.sidebar ul a,
.sidebar ul a:visited {
  color: #fff;
}

a,
a:visited {
  color: #000;
  text-decoration: none;
}
.whytxcontainer {
  display: flex;
  justify-content: space-evenly;
  max-width: 1000px;
}
.whytxholder {
  margin-left: 2rem;
  margin-top: -3rem;
  min-width: 350px;
}
.sectionwrapper h2 {
  font-size: 3.3rem;
  margin: 0;
  padding: 2rem;
  padding-left: 0;
}
.getstarted {
  margin: 0 auto;
  width: 30%;
  max-width: 800px;
  color: #fff;

  display: flex;
  width: 70%;
}
.assetholder {
  padding: 1rem;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;

  display: flex;
  padding: 3rem;
  max-width: 1200px;
}
.sectionwrapper {
  width: 100%;
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
}
.paddingfix ul li {
  text-indent: -5px;
  padding: 0.3rem;
  list-style: square;
}
.getstartedholder {
  background: #000;
  margin-right: 2rem;
  padding: 0.4rem;
  height: auto;
}

.h1holder {
  display: flex;
  margin-top: 7rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: relative;
  min-height: 250px;
}

h1 {
  font-size: 4.4rem;
  width: 100%;
  text-align: center;
}

.padding-fix h2 {
  margin-top: 3.5rem;
  font-size: 1.2rem;
}

.cursor::after {
  content: "|";
  text-align: left;
}

.blink::after {
  animation: blink2 ease 0.4s infinite;
}

.registrar:hover {
  opacity: 1;
}

.registrarholder {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  width: 30vw;
  margin: 0 auto;
}

.orange {
  background: #fe5b04;
}

@keyframes blink2 {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.options {
  text-align: center;
  flex-wrap: wrap;
  color: rgba(4, 17, 29, 0.75);
  transform-style: preserve-3d;
  margin: 0 auto;
  height: 20px;

  width: 270px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.optionsholder {
  height: 200px;
  width: 200px;
  margin: 150px auto;
  perspective: 1000px;
}

@keyframes rotar {
  100% {
    transform: rotateY(360deg);
  }
}

.lefterx img {
  left: 0;
}

.lefterx img,
.righterx img {
  width: 25px;
}

.lefterx {
  position: absolute;
  left: 0;
  cursor: pointer;
  cursor: pointer;
  top: 10px;
  left: -155px;
}

.carousel-control {
  width: 165px;
  height: 200px;
  cursor: pointer;
  z-index: 999999999;
}

.options .items {
  position: absolute;
  opacity: 0.3;
  opacity: 0.01;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  z-index: 0;
}

.options .items {
  position: absolute;
  opacity: 0.03;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  left: 150px;
  left: 170px;
  margin: 0;
  display: flex;
  justify-content: center;
}

section {
}

ol,
ul {
  list-style: none;
}

.lefterx {
  position: absolute;
  cursor: pointer;
}

.lefterx img {
  left: 0;
}

.lefterx img,
.righterx img {
  height: auto;
  position: absolute;
  top: 41%;
}

.carousel-control img {
  opacity: 0.2;
}

.righterx {
  position: absolute;
  right: 0;
  cursor: pointer;
  position: absolute;
  cursor: pointer;
  left: 575px;
  top: 10px;
}

.right {
  opacity: 1;
  z-index: 5000;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(1);
  opacity: 0.03;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  z-index: 0;
  width: 500px;
  left: 0;
  right: 0;
  top: 0;
}

.options li {
  color: #333;
  display: inline-block;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: grid;
  text-align: center;
  font-size: 20px;
  width: 200px;
}

.options .main p {
  transform: scale(1);
  font-size: 20px;
  padding-top: 3px;
}

.options li p {
  color: #333;
  font-weight: bold;
  text-align: center;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  text-align: center;
}

.options .left {
  opacity: 1;
  left: 0px;
  z-index: 5000;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  left: -150px;
  left: -105px;
}

.options .right {
  opacity: 1;
  z-index: 5000;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  left: 405px;
  left: 435px;
}

.options .main {
  opacity: 1;
  transform: scale(1);
  z-index: 3000;
  left: 150px;
  left: 170px;
  right: 0;
}

.bottom {
  transform: rotate3d(1, 1, 1, 125deg);
  background: red;
}

.side {
  position: relative;
  width: 408px;
  height: 577px;
}

.sides {
  transform: rotateY(-90deg) translateZ(100px);
}

.sides {
  position: absolute;
  transform-style: preserve-3d;
  height: 100%;
  width: 100%;
}

.black2 {
  background-color: #333;
}

.children {
  height: 100%;
  width: 100%;
  transform: rotateX(30deg);
  transform-origin: bottom;
  clip-path: polygon(44% 0, 44% 10%, 10% 10%, 9% 100%, 0 100%, 0 0);

  transform: rotateZ(86deg) rotateX(308deg);
}

.registrar {
  padding: 1rem;
  background: black;
  color: #fff;
  font-size: 1.25rem;
  margin-top: 5rem;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 2px;
  width: 175px;
}

.carousel-control:hover img {
  opacity: 1;
}

.leftline {
}

.leftline:before {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 50%;
  top: 39px;
  top: 54px;
  transform: translateY(-50%) rotateZ(346deg);
  background-color: #000;
  content: "";
  position: absolute;
  left: -5px;
}

.leftline:after {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 40px;
  top: 90px;
  transform: translateY(-50%) rotateZ(270deg);
  background-color: #000;
  content: "";
  position: absolute;
  left: -23px;
}

.mainline {
}

.mainline:before {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 20%;
  top: 85px;
  transform: translateY(-50%) rotateZ(270deg);
  background-color: #000;
  content: "";
  position: absolute;
  left: 247px;
}

.rightline {
}

.rightline:before {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 50%;
  top: 54px;
  transform: translateY(-50%) rotateZ(14deg);
  background-color: #000;
  content: "";
  left: 402px;
}

.rightline:after {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 40px;
  top: 90px;
  transform: translateY(-50%) rotateZ(270deg);
  background-color: #000;
  content: "";
  left: 515px;
}

.options {
  margin-top: -15px;
}

.orange {
  background: #fe5b04;
}

.inside {
  height: 345px;
}

.lowopacity .registrar {
  opacity: 0.15 !important;
}

.lowopacity .registrar:hover {
  opacity: 0.15;
}

.black {
  background-color: #000;
}

.paddingfix {
  padding-top: 5rem;
}

.paddingfix ul {
  margin: 0;
  margin-left: 1.25rem;
}

.black h2 {
  color: #fff;
}

.sectionwrapper img {
  max-height: 260px;
  width: auto;
}

.whytx {
  font-size: 1.2rem;
  line-height: 130%;
}

@media (min-width: 1150px) {
  .whytx {
    margin: 0 auto;
    width: 75%;
    max-width: 1200px;
  }
  .whytxcontainer {
    max-width: 1000px;
  }
  .vidholder iframe {
    width: 300px;
  }

  .sectionwrapper img {
    max-height: 285px;
  }
}

.whytx p {
  font-size: 1.3rem;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.assetholder img {
  max-width: 100%;
}

.assetholder div {
  background: black;
  padding: 0.5rem;
}

.accessassets {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.accessassets div {
  background: #fff;
  padding: 1.5rem;
  color: #000;
  font-size: 1.3rem;
  border-radius: 3px;
  cursor: pointer;
}

.watchvideoholder {
  width: 100%;
  display: flex;
  margin-top: 2rem;
}

.watchvideoholder div {
  background: #fff;
  padding: 1.5rem;
  color: #000;
  font-size: 1.3rem;
  border-radius: 3px;
  cursor: pointer;
}

.play {
  display: block;
  width: 68px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  top: 45%;
  left: 50%;
  z-index: 1;
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');

  filter: grayscale(100%);
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
  border: none;
}

.vidholder {
  position: relative;
  cursor: pointer;
}

.vidholder iframe {
  min-height: 275px;
  min-width: 500px;
  margin-right: auto;
}
.play:hover,
.vidholder:hover .play {
  filter: none;
}

.firstwrapper {
  min-height: 339px;
  padding-top: 2.5rem;

  min-height: 333px;
}

.imghighlight img {
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  filter: grayscale(100%);
}

.straightener {
  max-width: 1000px;
  margin: 0 auto;
}

.footer li {
  padding-top: 7px;
  font-size: 1.2rem;
}

.footer h2 {
  padding-bottom: 0.3rem;
  font-size: 2rem !important;
}

.footer .social img {
  height: 35px;
  width: auto;
  padding-top: 7px;
}

.footercenter {
  text-align: center;
}

.getstarted {
  transform: scale(0.6);
  opacity: 0;
  transition: all 250ms;
}

.bigger {
  opacity: 1;
  transform: scale(1);
}

.variablewidth {
  max-width: 1000px;
  width: 100%;
}

@media (min-width: 1100px) {
  .variablewidth {
    max-width: 1000px;
  }
}
.lefterx {
  width: 30px;
}
.righterx {
  width: 41px;
  left: 631px;
}

.optionsmobile {
  display: none;
}
.marginright4rem {
  margin-right: 4rem;
}
.whitebox {
  background: #fff;
  padding: 0.4rem;
  height: auto;
  width: 80%;
  margin: 0 auto;
}
.outer2 {
  background: #fff;
  padding: 0.4rem;
}
.whytxholder2 {
  background: #fff;
  margin-right: 2rem;
  padding: 0.4rem;
  height: auto;
}
.sectionwrapper .number {
  color: #000;
  border: 1px solid #000;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
@media (max-width: 768px) {
  .sectionwrapper .number {
    font-size: 1.3rem;
    margin-top: 0.5rem;
  }
  .whytxholder {
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  .whytxholder2 {
    margin-right: 0rem;
  }

  .outer2 {
    padding: 0;
  }
  .vidholder iframe {
    margin: 0 auto;
  }
  .whitebox {
    width: 95%;
  }
  .getstartedholder {
    margin-right: 0;
  }
  .marginright4rem {
    margin-right: 0;
    text-align: center;
    margin-bottom: 2rem;
  }

  .numberholder .getstartedholder {
    margin-right: 1rem;
  }
  .accessassets div {
    text-align: center;
    line-height: 120%;
    padding: 1rem;
    margin-top: 2rem;
    font-size: 1.3rem;
  }
  .inside h2 {
    font-size: 2rem;
  }
  .inside {
    height: 205px;
  }
  .h1holder {
    margin-top: 3rem;
  }

  .options {
    display: none;
  }
  .optionsmobile {
    display: block;
  }
  .optionsmobile .registrar {
    margin: 1rem auto;
    font-size: 2.25rem;
    width: 300px;
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .optionsmobile li:last-child {
    margin-bottom: 2rem;
  }
  .paddingfix ul li {
    font-size: 2rem;
    line-height: 120%;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .sectionwrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .assetholder {
    padding: 1rem;
    width: 100%;
  }
  .getstarted {
    width: 100%;
    font-size: 2rem;
  }
  .getstarted .whytx {
    font-size: 1.3rem;
  }
  .whytxcontainer {
    display: block;
  }
  .vidholder {
    display: flex;
    justify-content: center;
  }
  .whytxholder {
    margin-top: 2rem;
  }

  .content__inner {
    display: none;
  }

  .navsizer img {
    margin-right: auto;
    margin-left: 2rem;
  }

  .nav-lines {
    display: block;
  }
  .inside h1 {
    font-size: 4rem;
  }

  .sectionwrapper h2 {
    font-size: 2.8rem;
    margin: 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
  }
  .sectionwrapper .whytxholder h2 {
    padding-bottom: 2rem;
  }
  .assetholder {
    display: block;
  }
  .assetholder img {
    max-height: 315px;
  }
  .mitems {
  }
}
.articlesholder {
  display: flex;
  justify-content: space-evenly;
  width: 95%;
  max-width: 1000px;
  margin: 0 auto;
}
.articleholder {
  background: #fff;
  margin-right: 2rem;
  padding: 0.4rem;
  height: auto;
}
.articlewidth {
  width: 350px;
}
.articleholder .articleh2 {
  padding-top: 0.2rem;
  padding-bottom: 0.5rem;
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
  line-height: 130%;
}
@media (max-width: 768px) {
  .articlesholder {
    display: block;
    margin: 0 auto;
  }
  .articleholder {
    margin-right: 0;
  }
  .imghighlight img {
    max-height: 100% !important;
    height: auto !important;
  }
  .articlewidth {
    width: 100%;
    margin-bottom: 2rem;
  }
  .articlesholder p {
    font-size: 1.4rem;
    line-height: 120%;
  }
}
@media (max-width: 600px) {
  .inside h1 {
    font-size: 4rem;
  }
}
@media (max-width: 500px) {
  .inside h1 {
    font-size: 4rem;
  }
}

@media (max-width: 400px) {
  .vidholder iframe {
    max-width: 100%;
  }
  .inside h2 {
    font-size: 1.4rem;
  }
  .h1holder .inside h1 {
    font-size: 2.5rem;
  }
  .optionsmobile .registrar {
    font-size: 1.75rem;
    width: 80%;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
  .h1holder {
    margin-top: 2rem;
    min-height: auto !important;
  }
  .inside {
    height: auto;
  }
  .paddingfix ul li {
    font-size: 1.3rem;
  }
}
</style>
