import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/hey",
    name: "hey",
    component: () => import("../views/Hey.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/resources",
    name: "resources",
    component: () => import("../views/Resources.vue"),
  },
  {
    path: "/community",
    name: "community",
    component: () => import("../views/Community.vue"),
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("../views/Partners.vue"),
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "../",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
