<template>
  <div class="footer">
    <div class="variablewidth">
      <div class="footerholder">
        <div class="footerflex">
          <div class="footer-logo margin3rem" style="text-align: center">
            <img
              style="
                max-width: 140px;
                display: block;

                margin: 1.5rem auto;
              "
              src="images/heytx.png"
            />
          </div>

          <div class="margin3rem footernav">
            <h2 style="font-size: 1rem; font-size: 1.5rem">MENU</h2>
            <ul>
              <router-link to="/" @click="navclick(1, 'home')">
                <li>Home</li>
              </router-link>
              <router-link to="/about" @click="navclick(1, 'about')">
                <li>About</li>
              </router-link>
              <router-link to="/resources" @click="navclick(1, 'resources')">
                <li>Resources</li>
              </router-link>
              <router-link to="/community" @click="navclick(1, 'live sites')">
                <li>Live Sites</li>
              </router-link>
              <router-link to="/partners" @click="navclick(1, 'partners')">
                <li>Partners</li>
              </router-link>
            </ul>
          </div>

          <div class="social margin5rem">
            <h2>NEWSLETTER</h2>
            <iframe
              src="https://heytx.substack.com/embed"
              style="border: 0px solid #eee; background: white"
              frameborder="0"
              scrolling="no"
              class="iframe"
            ></iframe>
          </div>
          <div class="social">
            <h2 style="font-size: 1rem; font-size: 1.5rem">SOCIAL</h2>
            <a href="https://discord.gg/RCWU3QJvy7" target="_blank"
              ><img style="max-width: 100%" src="images/socials/discord.png"
            /></a>
            <a href="https://twitter.com/hey__tx" target="_blank"
              ><img style="max-width: 100%" src="images/socials/twitter.png"
            /></a>
            <a href="https://www.linkedin.com/company/hey-tx/" target="_blank"
              ><img style="max-width: 100%" src="images/socials/linkedin.png"
            /></a>
            <a href="https://www.meetup.com/hns-dweb-community/" target="_blank"
              ><img style="max-width: 100%" src="images/socials/meetup.png"
            /></a>
            <a
              href="https://www.youtube.com/channel/UC1GrATz6AKfLb-Mb2vmwtkw"
              target="_blank"
              ><img style="max-width: 100%" src="images/socials/youtube.png"
            /></a>
          </div>
        </div>
        <div class="footertext">
          Copyright © 2023 All Rights Reserved by Hey tx
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  emits: ["clicked"],
  methods: {
    navclick(e, val, text) {
      this.$emit("clicked", e, val, text);
    },
  },
};
</script>

<style scoped>
.footer .router-link-active {
  color: #000 !important;
}
.social h2 {
  padding-left: 0.65rem;
  font-size: 1rem;
  font-size: 1.5rem;
}
.footerholder {
  width: 98%;
  margin: 1.3rem auto;
}
.footerflex {
  display: flex;
  justify-content: space-between;
}
.footertext {
  padding-top: 4rem;
  font-size: 1.05rem;
  text-align: center;
}
@media (min-width: 1100px) {
  .variablewidth {
    max-width: 1000px;
  }
}
.variablewidth {
  max-width: 1000px;
  text-align: initial;
  width: 100%;
}
.footer li {
  padding-top: 7px;
  font-size: 1.2rem;
}

.footer h2 {
  padding-bottom: 0.3rem;
  font-size: 2rem !important;
}

.footer .social img {
  height: 35px;
  width: auto;
  padding: 3px;
  padding-top: 7px;
}

.tabs__nav {
  position: relative;
  margin-left: 1.5rem;
  margin-left: 0.75rem;
}

.tabs__nav-decoration {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: width 0.2s linear 0s, transform 0.2s ease-out 0s;
  background-color: black;
  border-radius: 1px;
  z-index: 1;
}

.tabs__nav-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  z-index: 5;
  padding: 0;
}

.tabs__nav-item {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  padding: 15px;
  cursor: pointer;
  /* font-size: 1.3rem; */
  border-radius: 2px;
  /* font-size: clamp(.8rem, 2vw, 1.3rem); */
  /* margin-left: 1rem; */
  font-size: clamp(0.9rem, 2vw, 1.3rem);
}

.tabs__nav li:not(:first-child) {
  /* margin-left: 1.5rem; */
  /* margin-left: clamp(.8rem, 2vw, 3.5rem); */
}

.tabs__nav li:first-child {
  /* margin-left: 1.8rem; */
  /* margin-left: clamp(.8rem, 2vw, 3.5rem); */
  /* margin-left: clamp(0rem, 1vw, 3.5rem); */
}

.tabs__nav-item.js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0.05s;
  color: #fff;
}

.tabs__panels {
  position: relative;
  margin-top: 30px;
}

.tabs__panel {
  position: absolute;
  top: 0;
  left: 0;
  transition: none;
  transform: scale(0.8);
  width: 100%;
  opacity: 0;
}

.tabs__panel.js-active {
  transition: all 0.25s linear 0s;
  transform: scale(1);
  opacity: 1;
}

.tabs__panel-card {
  display: flex;
  margin-bottom: 30px;
  padding: 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.tabs__panel-card:last-child {
  margin-bottom: 0;
}

.tabs__panel-card--spaced-between {
  justify-content: space-between;
}

.content__inner {
  /* width: 375px; */
  /* height: 550px; */
  width: 100%;
}

.tabs__nav {
  /* width: 48vw; */
}

.tabs__nav-list {
  position: relative;
  /* display: flex; */
  justify-content: space-between;
  list-style-type: none;
  z-index: 5;
  /* width: 100%; */
}

.tabs__nav-item {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  padding: 15px;
  cursor: pointer;
  display: inline-block;
}

header img {
  /* height: 70px; */
  width: auto;
  /* margin-right: 1rem; */
  margin-right: clamp(0rem, 1.7vw, 8rem);
  height: clamp(55px, 8.5vw, 70px);
}

header {
  border-bottom: 1px solid #000;
  height: 96px;
}

.navsizer {
  /* width: 1000px */
  /* width: clamp(630px, 79.5vw, 822px); */
  width: clamp(648px, 79.5vw, 985px);
}

.nav-lines {
  /* background: red; */
  margin-right: 2rem;
  display: none;
  height: 75px;
  /* width: 110%; */
}

.line {
  width: 46px;
  height: 4px;
  background: #000;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.line:before {
  top: 13px;
  content: "";
  width: 46px;
  height: 4px;
  background: #000;
  position: absolute;
  transition: 0.3s;
}

.line:after {
  top: -13px;
  content: "";
  width: 46px;
  height: 4px;
  background: #000;
  position: absolute;
  transition: 0.3s;
}

.nav-lines.active .line {
  background: #fff;
}

.nav-lines.active .line::after {
  top: 0;
  transform: rotate(-45deg);
}

.nav-lines.active .line::before {
  top: 0;
  transform: rotate(45deg);
}

.sidebar {
  height: 100%;
  /* height: 1000vh; */
  width: 100vw;
  /* position: fixed; */
  top: 96px;
  z-index: 1;
  right: 0;
  background-color: #000;
  transition: all 200ms;
  position: absolute;
  /* display: none; */
  transform: translateX(100vw);
}

.sidebar.active {
  /* transition: all 200ms; */
  /* position: relative; */
  transform: none;
}

.sidebar ul li {
  padding: 1.2rem;
  /* text-align: center; */
  font-size: 2.4rem;
  /* padding-lef */
  padding-left: 1.05rem;
}

.sidebar ul .current {
  background: #fff;
  color: #000;
}

.sidebar ul .current a,
.sidebar ul .current a:visited {
  color: #000;
}

.sidebar ul a,
.sidebar ul a:visited {
  color: #fff;
  /* background: #FFF; */
}

a,
a:visited {
  color: #000;
  text-decoration: none;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #000;
  padding: 1.3rem;
  padding-bottom: 0.45rem;
}

@media (max-width: 768px) {
  .footerflex {
    display: block;
    text-align: center;
  }
  .social h2 {
    padding-left: 0;
  }
  .social {
    margin-top: 2rem;
  }

  .footer {
    padding: 1rem;
  }
  .content__inner {
    display: none;
  }

  .navsizer img {
    margin-right: auto;
    margin-left: 2rem;
  }

  .nav-lines {
    display: block;
  }
  .footer-logo {
    display: none;
  }
}

.h1holder {
  display: flex;
  margin-top: 7rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h1 {
  font-size: 4.4rem;
  width: 100%;
  /* min-width: 843px; */
  text-align: center;
}

.padding-fix h2 {
  margin-top: 3.5rem;
  font-size: 1.2rem;
}

.cursor::after {
  content: "|";
  text-align: left;
}

.blink::after {
  animation: blink2 ease 0.4s infinite;
}

.registrar:hover {
  opacity: 1;
}

.registrarholder {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  width: 30vw;
  margin: 0 auto;
}

.orange {
  background: #fe5b04;
}

@keyframes blink2 {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

/* begin import */
.options {
  text-align: center;
  /* display: flex;
            align-items: center;
            justify-content: center; */
  flex-wrap: wrap;
  /* width: 46vw; */
  /* margin: 0 auto; */
  color: rgba(4, 17, 29, 0.75);
  transform-style: preserve-3d;
  /* height: 100%; */
  /* width: 100%; */
  margin: 0 auto;

  /* width: 46vw; */
  /* background: red; */
  height: 20px;

  /* width: 400px; */
  /* width: 307px; */
  width: 270px;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  /* This is a shorthand of
                                         translateX(-50%) and translateY(-50%) */
}

.optionsholder {
  height: 200px;
  width: 200px;
  margin: 150px auto;
  perspective: 1000px;
}

@keyframes rotar {
  100% {
    transform: rotateY(360deg);
  }
}

.lefterx img {
  left: 0;
}

.lefterx img,
.righterx img {
  width: 25px;
  /* width: 13%;
            height: auto;
            position: absolute;
            top: 35%; */
  /* opacity: 0.2; */
}

.lefterx {
  position: absolute;
  /* background: red; */
  /* top: -20px; */
  left: 0;
  cursor: pointer;
  top: 204px;
  /* left: 93px; */
  /* left: 93px !important; */

  cursor: pointer;
  /* left: 628px; */
  /* left: -220px; */
  top: 10px;
  /* left: -220px; */
  /* left: -200px; */
  left: -155px;
}

.carousel-control {
  /* position: absolute; */
  /* background: red; */
  /* top: 0; */
  width: 165px;
  height: 200px;
  /* right: 0; */
  cursor: pointer;
  /* opacity: 0.2; */
  z-index: 999999999;
  /* top: -9px; */
  /* background: #FFF; */
}

.options .items {
  position: absolute;
  /* left: 50px; */
  opacity: 0.3;
  opacity: 0.01;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  /* left: 300px; */
  z-index: 0;
  /* left: 0; */
  /* width: 500px; */
  /* left: 0; */
  /* right: 0; */
  /* top: 0; */
  /* margin-top: -15px; */
}

.options .items {
  position: absolute;
  /* left: 50px; */
  opacity: 0.03;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  z-index: 0;
  /* left: 0; */
  /* width: 500px; */
  left: 0;
  right: 0;
  top: 0;
  left: 150px;
  left: 170px;
  margin: 0;

  display: flex;
  justify-content: center;
  /* margin-top: -20px; */
}

section {
  /* we probalby dont need section */
  /* width: 14em; */
  /* height: 25em; */
  /* margin: 0 auto; */
  /* margin-top: 10px; */
  /* position: relative; */
}

ol,
ul {
  list-style: none;
}

.lefterx {
  position: absolute;
  /* background: red; */
  /* top: -20px; */
  /* left: 0; */
  cursor: pointer;
}

.lefterx img {
  left: 0;
}

.lefterx img,
.righterx img {
  /* width: 13%; */
  height: auto;
  position: absolute;
  /* top: 35%; */
  top: 41%;
  /* opacity: 0.2; */
}

.carousel-control img {
  opacity: 0.2;
}

.righterx {
  position: absolute;
  /* background: red; */
  /* top: -20px; */
  right: 0;
  cursor: pointer;
  position: absolute;
  /* background: red; */
  /* top: -20px; */
  /* right: 0; */
  cursor: pointer;
  left: 575px;
  top: 10px;
}

.right {
  opacity: 1;
  /* left: -600px; */
  z-index: 5000;
  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(1);

  opacity: 0.03;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  z-index: 0;
  /* left: 0; */
  width: 500px;
  left: 0;
  right: 0;
  top: 0;
}

.options li {
  /* height: 343px; */
  color: #333;
  display: inline-block;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: grid;
  text-align: center;
  font-size: 20px;
  width: 200px;
}

.options .main p {
  /* display: block !important; */
  transform: scale(1);
  font-size: 20px;
  padding-top: 3px;
}

.options li p {
  color: #333;
  font-weight: bold;
  /* font-size: 20px; */
  text-align: center;
  /* padding-top: 5px; */
  /* width: 300px; */
  /* margin-top: 1.175em; */
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  /* width: 1000px; */
  /* position: absolute; */
  text-align: center;
  /* display: contents; */
}

.options .left {
  opacity: 1;
  /* left: -155px; */
  left: 0px;
  z-index: 5000;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  left: -150px;
  left: -105px;
  /* left: clamp(-150px, -13.5vw, -50px); */
}

.options .right {
  opacity: 1;
  /* left: 600px; */
  /* left: 450px; */
  z-index: 5000;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  left: 405px;
  left: 435px;
  /* left: clamp(50px, 23.5vw, 450px); */
  /* left: clamp(50px, 55.5vw, 450px); */
  /* left: clamp(50px, 48.5vw, 450px); */
  /* left: clamp(50px, 47.5vw, 450px); */
}

.options .main {
  opacity: 1;
  transform: scale(1);
  /* left: 50px; */
  z-index: 3000;
  /* width: 440px; */
  /* left: 0; */
  /* left: 300px; */
  left: 150px;
  left: 170px;
  right: 0;
  /* top: -30px; */
}

.bottom {
  transform: rotate3d(1, 1, 1, 125deg);
  background: red;
}

.side {
  position: relative;
  width: 408px;
  height: 577px;
}

.sides {
  transform: rotateY(-90deg) translateZ(100px);
}

.sides {
  position: absolute;
  transform-style: preserve-3d;
  height: 100%;
  width: 100%;
}

.black2 {
  background-color: #333;
}

.children {
  height: 100%;
  width: 100%;
  transform: rotateX(30deg);
  transform-origin: bottom;
  clip-path: polygon(44% 0, 44% 10%, 10% 10%, 9% 100%, 0 100%, 0 0);

  transform: rotateZ(86deg) rotateX(308deg);
}

.registrar {
  padding: 1rem;
  background: black;
  color: #fff;
  font-size: 1.25rem;
  margin-top: 5rem;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;
  /* width: 200px; */
  border-radius: 2px;
  width: 175px;
}

.carousel-control:hover img {
  opacity: 1;
}

.leftline {
  /* background: red;
            width: 20px;
            height: 20px; */
}

.leftline:before {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 50%;
  /* top: 10%; */
  top: 39px;
  top: 54px;
  transform: translateY(-50%) rotateZ(346deg);
  /* padding: 30px; */
  background-color: #000;
  content: "";
  position: absolute;
  /* left: -39px; */
  left: -5px;
}

.leftline:after {
  position: absolute;
  height: 1.5px;
  left: 50%;

  width: 40px;
  /* top: 10%; */
  top: 75px;

  top: 90px;
  transform: translateY(-50%) rotateZ(270deg);
  /* padding: 30px; */
  background-color: #000;
  content: "";
  position: absolute;
  /* left: -14px; */
  /* left: -57px; */
  /* left: 7px; */
  left: -23px;
}

.mainline {
  /* background: red;
            width: 20px;
            height: 20px; */
}

.mainline:before {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 20%;
  /* top: 10%; */
  top: 85px;
  transform: translateY(-50%) rotateZ(270deg);
  /* padding: 30px; */
  background-color: #000;
  content: "";
  position: absolute;
  left: 247px;
}

.rightline {
  /* background: red;
            width: 20px;
            height: 20px; */
}

.rightline:before {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 50%;

  /* top: 10%; */
  top: 54px;
  transform: translateY(-50%) rotateZ(14deg);
  /* padding: 30px; */
  background-color: #000;
  content: "";
  /* position: absolute; */
  left: 0;
  left: 402px;
}

.rightline:after {
  position: absolute;
  height: 1.5px;
  left: 50%;
  width: 40px;
  /* top: 10%; */
  top: 90px;
  transform: translateY(-50%) rotateZ(270deg);
  /* padding: 30px; */
  background-color: #000;
  content: "";
  /* position: absolute; */
  left: -14px;
  left: 549px;
  left: 515px;
}

.options {
  /* margin-top: 30px; */
  margin-top: -15px;
  /* margin-top: 15px; */
}

.orange {
  background: #fe5b04;
}

.inside {
  height: 385px;
}

.lowopacity .registrar {
  opacity: 0.15 !important;
}

.lowopacity .registrar:hover {
  opacity: 0.15;
}

.sectionwrapper {
  width: 100%;
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
}

.black {
  background-color: #000;
  /* padding-top: 5rem; */
}

.paddingfix {
  padding-top: 5rem;
}

.paddingfix ul {
  margin: 0;
  margin-left: 1.25rem;
}

.paddingfix ul li {
  text-indent: -5px;
  padding: 0.3rem;
  list-style: square;
}

.black h2 {
  color: #fff;
  /* padding-left: 0; */
}

.sectionwrapper img {
  max-height: 260px;
  width: auto;
}

.whytx {
  font-size: 1.2rem;
  line-height: 130%;
  /* width: 75%; */
}

@media (min-width: 1100px) {
  .whytx {
    /* width: 100%; */
    margin: 0 auto;
    /* width: 75%; */
    max-width: 1200px;
  }

  .sectionwrapper img {
    max-height: 285px;
  }
}

.whytx p {
  font-size: 1.3rem;
}

.whytxholder {
  margin-left: 2rem;
  margin-top: -3rem;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.assetholder {
  padding: 1rem;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  /* align-items: flex-start;
            align-content: flex-start; */
}

.assetholder img {
  /* height: 75%; */
  /* width: auto; */
  max-width: 100%;
}

.assetholder div {
  background: black;
  padding: 0.5rem;
  /* height: 50px; */
  /* width: 200px; */
  /* margin: 2rem; */
}

.accessassets {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.accessassets div {
  background: #fff;
  /* width: 200px; */
  padding: 1.5rem;
  color: #000;
  font-size: 1.3rem;
  border-radius: 3px;
  cursor: pointer;
}

.watchvideoholder {
  width: 100%;
  /* margin: 0 auto; */
  display: flex;
  /* justify-content: center; */
  margin-top: 2rem;
}

.watchvideoholder div {
  background: #fff;
  /* width: 200px; */
  padding: 1.5rem;
  color: #000;
  font-size: 1.3rem;
  border-radius: 3px;
  cursor: pointer;
}

.play {
  display: block;
  width: 68px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  top: 45%;
  left: 50%;
  z-index: 1;
  background-color: transparent;
  /* background: red; */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');

  filter: grayscale(100%);
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
  border: none;
}

.vidholder {
  position: relative;
  cursor: pointer;
}

.play:hover,
.vidholder:hover .play {
  filter: none;
}

.firstwrapper {
  /* min-height: 75vh; */
  min-height: 433px;
}

.getstarted {
  margin: 0 auto;
  width: 30%;
  max-width: 800px;
  color: #fff;
}

.imghighlight img {
  /* border: 1.5px solid #000; */
  /* height: 140px; */
  width: 100%;

  max-height: 100px;
  overflow: hidden;
}

.straightener {
  max-width: 1000px;
  margin: 0 auto;
}

.footer li {
  padding-top: 7px;
  font-size: 1.2rem;
}

.footer h2 {
  padding-bottom: 0.3rem;
  font-size: 2rem !important;
}

.footer .social img {
  height: 35px;
  width: auto;
  padding-top: 7px;
}

.footercenter {
  text-align: center;
}

.getstarted {
  transform: scale(0.6);
  opacity: 0;
  transition: all 250ms;
}

.bigger {
  opacity: 1;
  transform: scale(1);
}

.variablewidth {
  max-width: 1000px;
  width: 100%;
}

@media (min-width: 1100px) {
  .variablewidth {
    max-width: 1000px;
  }
}

.margin3rem {
  margin-right: 3rem;
}
.margin5rem {
  margin-right: 5rem;
}
.iframe {
  width: 280px;
  height: 100px;
}
@media (max-width: 768px) {
  .iframe {
    width: 220px;
  }
  .margin3rem {
    margin-right: 1rem;
  }
  .margin5rem {
    margin-right: 1rem;
  }
  .footertext {
    font-size: 1rem;
    line-height: 120%;
  }
}
</style>
