<template>
  <div class="overlayx" @click="close">
    <div class="modal">
      <div style="display: flex">
        <h2>Welcome to the Future of Domains</h2>
        <div
          @click="closepopup"
          style="
            margin-left: auto;
            font-size: 3rem;
            margin-top: -1rem;
            cursor: pointer;
          "
        >
          &times;
        </div>
      </div>

      <div class="modal-content">
        <div class="modal-image">
          <img src="images/heylogo.png" alt="Descriptive Alt Text" />
        </div>

        <div class="modal-actions">
          <button class="cta-btn modal-btn" @click="goToHeyPage">
            Explore .hey
          </button>
          <button class="cta-btn modal-btn" @click="buyTld">
            Buy on HNS.ID
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PupUpHey",
  data() {
    return {
      name: "",
      email: "",
      url: "",
      loading: 0,
      success: 0,
      errorMessage: "",
    };
  },
  props: ["popup"],
  emits: ["closemodal"],
  methods: {
    goToHeyPage() {
      localStorage.setItem("modalClosed", "true");
      document.querySelector('a[href="/hey"]').click();
    },
    buyTld() {
      localStorage.setItem("modalClosed", "true");
      window.open("https://hns.id/tld/hey/domains", "_blank");
    },
    closepopup() {
      this.$emit("closemodal");
    },
    submit(e) {
      e.preventDefault();
      this.loading = 1;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          subject: `Partner Request from ${this.email}`,
          message:
            `Name: ${this.name} ` +
            ` Email: ${this.email} ` +
            ` Message:  ${this.url} `,
        }),
      };
      fetch("./mailer/sendmail.php", requestOptions)
        .then(async (response) => {
          // const data = await response.json();
          this.loading = 0;
          // check for error response
          if (!response.ok) {
            this.errorMessage =
              "There was an error contacting the server. Please try again.";
          }

          this.success = 1;
        })
        .catch(() => {
          this.loading = 0;
          this.errorMessage =
            "There was an error contacting the server. Please try again.";
        });
    },
    close(e) {
      if (e.target.closest(".close") || !e.target.closest(".modal")) {
        this.$emit("closemodal");
      }
    },
  },
};
</script>

<style scoped>
.overlayx {
  position: fixed; /* Sit on top of the page content */
  display: block;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 10; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

@keyframes openup {
  0% {
    transform: translate(-50%, -50%) scale(0.56);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2rem;
  min-width: 500px;
  max-width: 600px;
  animation: openup 150ms;
}
h2 {
  font-size: 2rem;
  padding-bottom: 2rem;
  font-weight: 600;
  text-align: center;
}
.modal {
  cursor: auto;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 1rem;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #000;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 1rem;
}

.loadholder {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.9;
}

.ring {
  display: block;
  width: 80px;
  height: 80px;

  position: absolute;
  left: 43%;
  top: 36%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2rem;
}

.ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 #ccc #000 #ccc;
  animation: ring 1.2s linear infinite;
}
@media (max-width: 768px) {
  .modal {
    min-width: 100%;
    min-height: 100%;
  }
  .modal h2 {
    margin-top: 30%;
  }
  #contact input[type="text"][data-v-d91dda68],
  #contact input[type="email"][data-v-d91dda68],
  #contact input[type="tel"][data-v-d91dda68],
  #contact input[type="url"][data-v-d91dda68],
  #contact textarea[data-v-d91dda68] {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  #contact button[type="submit"] {
    font-size: 2rem;
    padding: 2rem;
  }
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.modal-image {
  margin: 0;
}

.modal-image img {
  width: 100%;
  max-height: 120px;
  /* max-width: 500px; */
  width: auto;
  display: block;
  margin: 0 auto;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal-btn {
  width: 48%; /* So that there's a tiny gap between them */
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .modal-btn {
    width: 100%;
  }
}

/* ... (Your previous styles) ... */

h2 {
  font-size: 2rem;
  line-height: 1.3; /* Adjust this value for the desired spacing between lines in the h2 element */
  padding-bottom: 2rem;
  text-align: center;
}

.modal-text {
  padding: 0.5rem; /* Adjusted horizontal padding */
  line-height: 1.3;
}

.modal-text p {
  /* margin-bottom: 1.5rem; Provides some spacing below the content text. Adjust as necessary */
}

.cta-btn.modal-btn {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 17px 20px; /* Adjust for desired button size */
  font-size: 1rem; /* Adjust for desired font size */
  cursor: pointer; /* Makes it clear that this is a clickable element */
  transition: none; /* Ensures no style changes on hover */
  margin-bottom: 0;
}

/* Removes focus styling for cleaner look, but might want to reconsider for accessibility */
.cta-btn.modal-btn:focus {
  outline: none;
}
</style>
